import { Link } from 'react-router-dom'
import Cookies from 'js-cookie'
import { useEffect } from 'react'

export default function CookieBanner() {
  useEffect(() => {
    if (Cookies.get('ww-consent-cookie')) {
      document.getElementById('cookie_banner')?.remove()
    }
  }, [])

  const acceptCookies = () => {
    Cookies.set('ww-consent-cookie', 'true', { expires: 30 })
    document.getElementById('cookie_banner')?.remove()
  }

  return (
    <div className='cookie-banner gap-x-6' id='cookie_banner'>
      <p className='text-sm leading-snug text-slate-700'>
        We use cookies to improve the experience. You are agree to consent to
        our{' '}
        <Link
          to='/cookie-policy'
          className='underline hover:text-primary hover:no-underline'>
          use of cookies
        </Link>
        .
      </p>
      <button
        type='button'
        className='button text-nowrap'
        onClick={acceptCookies}>
        Accept
      </button>
    </div>
  )
}
