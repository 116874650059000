import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { useState } from 'react'
import Preloader from './Preloader'

type Inputs = {
  first_name: string
  last_name: string
  email: string
  phone_number: string
  company_size: string
}

export default function Footer() {
  const [isLoading, setLoad] = useState(false)

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Inputs>({
    reValidateMode: 'onBlur',
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      company_size: '',
    },
  })

  const submitForm = async (data: Inputs) => {
    try {
      setLoad(true)
      const formData = new FormData()
      formData.append('first_name', data.first_name)
      formData.append('last_name', data.last_name)
      formData.append('email', data.email)
      formData.append('phone_number', data.phone_number)
      formData.append('company_size', data.company_size)
      const response = await axios.post(
        process.env.REACT_APP_FORM_ENDPOINT as string,
        formData,
        { headers: { Accept: 'application/json' } }
      )
      setLoad(false)
      if (response.status === 200) {
        alert('Your data has been submitted, we will contact you soon.')
        setValue('first_name', '')
        setValue('last_name', '')
        setValue('email', '')
        setValue('phone_number', '')
        setValue('company_size', '')
      } else {
        alert('Failed to submit your data. Please try again later.')
      }
    } catch (err: any) {
      alert(('err ' + err.message) as string)
      setLoad(false)
    }
  }

  return (
    <footer className='footer relative bg-soft-blue py-6 md:py-10'>
      {isLoading && <Preloader />}
      <div className='container'>
        <div className='mb-2 grid grid-cols-1 gap-y-6 md:mb-0 md:grid-cols-12 md:gap-x-8 md:gap-y-0'>
          {/* left */}
          <div className='md:col-span-5 lg:col-span-4'>
            <div className='flex flex-col md:h-full md:justify-between md:self-stretch'>
              <Link to='/' className='mb-4 md:basis-16'>
                <img
                  src='/img/site-logo-black.png'
                  alt='WorkWish'
                  className='mb-4 block h-14 max-w-full lg:mb-8'
                />
              </Link>
              <aside className='md:flex-1'>
                <ul className='mb-6 grid grid-cols-2 gap-6 md:grid-cols-1'>
                  <li>
                    <Link
                      to='/about'
                      className='text-base hover:text-primary md:text-lg'>
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link
                      to='/terms'
                      className='text-base hover:text-primary md:text-lg'>
                      Term of Service
                    </Link>
                  </li>
                  <li>
                    <Link
                      to='/privacy'
                      className='text-base hover:text-primary md:text-lg'>
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link
                      to='/cookie-policy'
                      className='text-base hover:text-primary md:text-lg'>
                      Cookies Policy
                    </Link>
                  </li>
                </ul>
              </aside>
              <p className='basis-10 flex-col justify-end align-bottom text-[10px] text-neutral-500 md:flex'>
                © Copyright 2024. All Rights Reserved.
              </p>
            </div>
          </div>
          {/* right */}
          <div className='md:col-span-7 lg:col-span-8'>
            <h3 className='mb-4 text-3xl font-bold'>Contact Us</h3>
            <p className='mb-6 text-sm leading-relaxed lg:text-lg'>
              Start with us by filling out the information below,
              <br />
              and we will contact you as soon as possible.
            </p>
            <form className='form' onSubmit={handleSubmit(submitForm)}>
              <div className='group max-w-sm'>
                <label className='label'>
                  First name <span className='req'>*</span>
                </label>
                <input
                  type='text'
                  className='input'
                  {...register('first_name', {
                    required: {
                      value: true,
                      message: 'This field is required.',
                    },
                  })}
                />
                {errors.first_name && (
                  <p className='error-message'>{errors.first_name.message}</p>
                )}
              </div>
              <div className='group max-w-sm'>
                <label className='label'>
                  Last name <span className='req'>*</span>
                </label>
                <input
                  type='text'
                  className='input'
                  {...register('last_name', {
                    required: {
                      value: true,
                      message: 'This field is required.',
                    },
                  })}
                />
                {errors.last_name && (
                  <p className='error-message'>{errors.last_name.message}</p>
                )}
              </div>
              <div className='group max-w-sm'>
                <label className='label'>
                  Work Email address <span className='req'>*</span>
                </label>
                <input
                  type='email'
                  className='input'
                  {...register('email', {
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: 'Invalid email address.',
                    },
                    required: {
                      value: true,
                      message: 'This field is required.',
                    },
                  })}
                />
                {errors.email && (
                  <p className='error-message'>{errors.email.message}</p>
                )}
              </div>
              <div className='group max-w-sm'>
                <label className='label'>
                  Phone number <span className='req'>*</span>
                </label>
                <input
                  type='tel'
                  className='input'
                  {...register('phone_number', {
                    required: {
                      value: true,
                      message: 'This field is required.',
                    },
                  })}
                />
                {errors.phone_number && (
                  <p className='error-message'>{errors.phone_number.message}</p>
                )}
              </div>
              <div className='group max-w-sm'>
                <label className='label'>
                  Company size <span className='req'>*</span>
                </label>
                <select
                  className='input'
                  {...register('company_size', {
                    required: {
                      value: true,
                      message: 'This field is required.',
                    },
                  })}>
                  <option value=''></option>
                  <option value='Less than 50'>Less than 50</option>
                  <option value='51-100'>51-100</option>
                  <option value='101-500'>101-500</option>
                  <option value='501-1000'>501-1000</option>
                  <option value='More than 1000'>More than 1000</option>
                </select>
                {errors.company_size && (
                  <p className='error-message'>{errors.company_size.message}</p>
                )}
              </div>
              <button className='button'>Send</button>
              <p className='mt-6 text-[10px]'>
                By submitting your information,
                <br /> you agree to workwish{' '}
                <Link
                  to='/terms'
                  className='underline hover:text-primary hover:no-underline'>
                  Terms of Service
                </Link>{' '}
                and{' '}
                <Link
                  to='/privacy'
                  className='underline hover:text-primary hover:no-underline'>
                  Privacy Policy
                </Link>
                .
              </p>
            </form>
          </div>
        </div>
      </div>
    </footer>
  )
}
