export default function Preloader() {
  return (
    <div className='preloader absolute inset-0 z-10 h-full w-full'>
      <div className='backdrop inset-0 z-10 h-full w-full bg-white/90'></div>
      <div className='content absolute inset-0 z-20 mx-auto flex h-full w-full items-center justify-center self-stretch text-center'>
        <img
          src='/img/preloader.gif'
          className='mx-auto block h-[128px] w-[128px]'
          alt='Preloader'
        />
      </div>
    </div>
  )
}
