import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export default function Homepage() {
  const settings = {
    dots: false,
    className: 'center',
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    swipeToSlide: true,
    centerPadding: '24px',
    autoplay: true,
    autoplaySpeed: 3000,
  }

  return (
    <div className='homepage-wrapper mb-14 lg:mb-20'>
      <section className='top relative '>
        {/* curve */}
        <div className='header-curve'>
          {/* desktop */}
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 1200 120'
            preserveAspectRatio='none'
            className='desktop-curve'>
            <path
              d='M0,0V7.23C0,65.52,268.63,112.77,600,112.77S1200,65.52,1200,7.23V0Z'
              fill='#002586'></path>
          </svg>
          {/* mobile */}
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 1200 120'
            preserveAspectRatio='none'
            className='mobile-curve'>
            <path
              d='M0,0V7.23C0,65.52,268.63,112.77,600,112.77S1200,65.52,1200,7.23V0Z'
              fill='#002586'></path>
          </svg>
        </div>
        <div className='container'>
          <div className='pt-4 text-center md:pt-8 lg:pt-6 xl:pt-8'>
            <h1 className='mb-4 text-5xl font-bold leading-none text-white lg:text-[64px] xl:text-[72px]'>
              Build workplace
            </h1>
            <h2 className='mb-8 text-4xl font-bold leading-none text-secondary lg:text-[56px] xl:text-[64px]'>
              that work!
            </h2>
            <p className='mx-auto max-w-[60%] text-xl leading-snug text-white lg:max-w-full lg:text-2xl lg:leading-none'>
              People success as a business strategy.
            </p>
          </div>
        </div>
      </section>
      {/* intro */}
      <section className='intro mb-14 mt-[120px] lg:mb-20 lg:mt-[150px]'>
        <div className='container'>
          <p className='mx-auto max-w-[90%] text-center text-lg leading-snug md:max-w-lg md:text-xl lg:max-w-2xl lg:text-3xl lg:leading-normal'>
            Our mission is to deliver amazing <br className='hidden lg:block' />
            <strong>people data and insights</strong> to help you to{' '}
            <br className='hidden lg:block' />
            <strong>find the next leadership successors</strong> and{' '}
            <strong>best fit people for your company</strong>.
          </p>
        </div>
      </section>
      {/* features */}
      <section className='features mb-14 lg:mb-20'>
        <div className='container'>
          <div className='grid grid-cols-1 gap-y-10 sm:grid-cols-3 sm:gap-x-4 lg:gap-x-10'>
            <div className='feature-item'>
              <h3 className='mb-3 text-center text-3xl font-bold leading-none'>
                Candidate
              </h3>
              <img
                src='/img/candidate.jpg'
                alt=''
                className='mb-4 rounded-[32px] lg:mb-6'
              />
              <p className='text-center text-lg leading-snug sm:text-base lg:text-xl lg:leading-relaxed'>
                To ensure the recruitment who align with the company's vision,
                mission, culture, and job position.
              </p>
            </div>
            <div className='feature-item'>
              <h3 className='mb-3 text-center text-3xl font-bold leading-none'>
                Talent
              </h3>
              <img
                src='/img/talent.jpg'
                alt=''
                className='mb-4 rounded-[32px] lg:mb-6'
              />
              <p className='text-center text-lg leading-snug sm:text-base lg:text-xl lg:leading-relaxed'>
                To ensure that you don't miss out on top performance to retain,
                develop and groom to grow for new challenge.
              </p>
            </div>
            <div className='feature-item'>
              <h3 className='mb-3 text-center text-3xl font-bold leading-none'>
                Corporate
              </h3>
              <img
                src='/img/corporate.jpg'
                alt=''
                className='mb-4 rounded-[32px] lg:mb-6'
              />
              <p className='text-center text-lg leading-snug sm:text-base lg:text-xl lg:leading-relaxed'>
                To ensure that all employees have the same vision, mission and
                goals, working base on your company culture.
              </p>
            </div>
          </div>
        </div>
      </section>
      {false && (
        <>
          {/* slider */}
          <section className='logo'>
            <div className='container'>
              <p className='mb-8 text-center text-lg text-neutral-500'>
                Organization trust WorkWish
              </p>
              {/* mobile logo */}
              <div className='grid grid-cols-3 gap-8 md:grid-cols-4 lg:!hidden'>
                {[...Array(9)].map((_, index) => (
                  <div
                    key={index}
                    className='mx-auto text-center outline-none ring-0'>
                    <img
                      src='/img/thumbnail.jpg'
                      className='mx-auto block h-[96px] w-[96px] rounded-full outline-none ring-0'
                      alt='partner logo'
                    />
                  </div>
                ))}
              </div>
              {/* slider */}
              <div className='slider-block hidden lg:!block'>
                <Slider {...settings}>
                  {[...Array(14)].map((_, index) => (
                    <div
                      key={index}
                      className='mx-auto text-center outline-none ring-0'>
                      <img
                        src='/img/thumbnail.jpg'
                        className='mx-auto block h-[120px] w-[120px] rounded-full outline-none ring-0'
                        alt='partner logo'
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </section>
        </>
      )}
    </div>
  )
}
