export default function CookiePolicy() {
  return (
    <div className='static-wrapper'>
      <div className='container'>
        <h1 className='entry-title'>Cookie Policy</h1>
        <h4 className='mb-8 text-slate-500'>Last updated May 10, 2024</h4>
        <h3 className='section-title'>What are Cookies?</h3>
        <p className='entry-content'>
          Cookies are text files stored on your computer’s browser directory or
          program data subfolders. Cookies are created when you use your browser
          to visit a website that uses cookies to keep track of your movements
          within the site. If you wish to learn more about cookies, visit
          https://www.allaboutcookies.org/
        </p>
        <h3 className='section-title'>How Do We Use Cookies?</h3>
        <p className='entry-content'>
          Cookies can be ‘session cookies’ or ‘persistent’. Session cookies are
          temporary and support certain actions on the website then close when
          you leave the website. Persistent cookies remain on a device for a set
          period of time and are activated each time you, the user, visits our
          website.
        </p>
        <h2 className='large-title'>The Cookies We Set</h2>
        <h3 className='section-title'>Strictly Necessary</h3>
        <p className='entry-content'>
          If you create an account with us then we will use cookies for the
          management of the signup process and general administration. These
          cookies will usually be deleted when you log out, however in some
          cases they may remain afterwards to remember your site preferences
          when logged out. This site offers e-commerce and payment facilities so
          some cookies are essential to ensure that your order is remembered
          between pages so that we can process it properly.
        </p>
        <h3 className='section-title'>Third-party Cookies</h3>
        <p>
          These cookies will be determined by the third parties such as Google
          Analytics
        </p>
        <h2 className='large-title'>Cookies Setting</h2>
        <p className='entry-content'>
          Users can disable cookies on their browsers, by following these steps:
        </p>
        <p className='entry-content !mb-2 font-bold'>For Android (Chrome)</p>
        <p className='entry-content'>
          https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=en&oco=1
        </p>
        <p className='entry-content !mb-2 font-bold'>For Apple Safari</p>
        <p className='entry-content'>
          https://support.apple.com/en-gb/guide/safari/sfri11471/mac
        </p>
        <p className='entry-content !mb-2 font-bold'>For Google Chrome</p>
        <p className='entry-content'>
          https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en
        </p>
        <p className='entry-content !mb-2 font-bold'>For Microsoft Edge</p>
        <p className='entry-content'>
          https://support.microsoft.com/search/results?query=Tracking+Protection+for+desktop&isEnrichedQuery=false
        </p>
        <p className='entry-content !mb-2 font-bold'>For Mozilla Firefox</p>
        <p className='entry-content'>
          https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US
        </p>
        <p className='entry-content !mb-2 font-bold'>For Opera</p>
        <p className='entry-content'>
          https://help.opera.com/en/latest/web-preferences/
        </p>
        <p className='entry-content !mb-2 font-bold'>
          For Iphone or Ipad (Chrome)
        </p>
        <p className='entry-content'>
          https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DiOS&hl=en&oco=1
        </p>
        <p className='entry-content !mb-2 font-bold'>
          For Iphone or Ipad (Safari)
        </p>
        <p className='entry-content'>
          https://support.apple.com/en-us/HT201265
        </p>
        <p className='entry-content'>
          If you need more information please contact us at: contact@workwish.co
        </p>
      </div>
    </div>
  )
}
