import { Link } from 'react-router-dom'

export default function Privacy() {
  return (
    <div className='static-wrapper'>
      <div className='container'>
        <h1 className='entry-title'>Privacy Policy</h1>
        <h4 className='mb-8 text-slate-500'>Last updated May 10, 2024</h4>
        <p className='entry-content'>
          Welcome to workwish, a career services platform that connects user and
          employers to democratize the job finding experience. From the very
          beginning, our goal has been to design privacy into our platform in a
          way that respects the rights of user, and the needs of employers. We
          understand that user data privacy is a hot topic these days so we want
          to make clear the very basic privacy principles that guide our
          policies and practices:
        </p>
        <h3 className='section-title'>Welcome to workwish</h3>
        <p className='entry-content'>
          This Privacy Policy applies to your use of the workwish.co website and
          the workwish Service (collectively “the workwish Service” or “the
          Service”), as well as your relationship with ROBORA Company Limited.
          (“workwish”). This policy is part of workwish’s Terms of Service. By
          using our Service, you agree to both our Terms of Service and this
          Privacy Policy; if you do not agree, please do not use the Service.
        </p>
        <h3 className='section-title'>What information we collect</h3>
        <p className='entry-content'>
          We collect personal and non-personal data from you when you use our
          Services. Personal Data is data that includes a personal identifier
          like your name, email or address, phone number, IP address or device
          identifier, or is data that could reasonably be linked back to you. It
          also may include demographic information, geolocation information,
          educational information, or commercial information as described below.
        </p>
        <p className='entry-content'>
          You need to have an account to use workwish. You can create an account
          on workwish as a user seeking a job or career advice or as an employer
          looking for exciting new talent.
        </p>

        <ul>
          <li>
            <strong>User data:</strong>
            <br />
            We receive personal data about you when you create a User Account,
            update your profile, respond to questions or surveys on our website
            or otherwise use our Site, from our partner, and from other third
            party data sources.
          </li>
          <li>
            <strong>From you, your account and your profile:</strong>
            <br />
            You can choose to share additional information with us, such as an
            updated email address, phone number, work experience, resume and
            transcript, and answers to questions about your interests and
            activities that will be covered by this Privacy Policy and our{' '}
            <Link
              to='/terms'
              className='underline hover:text-primary hover:no-underline'>
              Terms of Service
            </Link>
            . You can also share this information by updating your profile,
            uploading documents that include personal information, or answering
            questions or surveys (such as the First Destination Survey) we email
            you or present to you on the Services. We will not use your phone
            number to send any commercial or marketing messages to you.
          </li>
          <li>
            <strong>From employer reviews:</strong>
            <br />
            We receive any personal information you choose to include in
            submitted employer reviews. workwish collects this information even
            if the review is not published or made public.
          </li>
          <li>
            <strong>From communications, transcripts or recordings:</strong>{' '}
            <br />
            We receive and store personal information when you choose to
            participate in a video or audio meeting or call or webinar initiated
            through the workwish Service or when you choose to communicate with
            other users by way of video, audio, or messaging during any workwish
            video or audio conference or call. By participating in workwish
            Communications, you are giving workwish consent to store the content
            and recordings of any workwish Communications. For audio or video
            recordings, you will receive a notification (visual or otherwise)
            when recording is enabled, and you should leave the meeting or
            webinar if you do not consent to being recorded. If we use a third
            party video and/or communications service provider to facilitate
            workwish Communications, that service provider may not use your
            personal information or the content of the communications for any
            purpose other than to provide the service to us.
          </li>
          <li>
            <strong>From third parties:</strong> <br />
            We may receive additional information about you from other partner
            or career counseling organizations, or from other sources.
          </li>
          <li>
            <strong>Usage and log data:</strong> <br />
            When you visit the Site, we log and store your IP Address and
            technical information about your visit like your browser type and
            how you progressed through the Site, where you abandoned it, etc.
            (“Usage Data”). We can use your IP address to determine your general
            location.
          </li>
          <li>
            <strong>Mobile data:</strong> <br />
            When you use the workwish mobile site, we collect analytic
            information about your device, such as IP address, clickstream, and
            other.
          </li>
          <li>
            <strong>Precise location data:</strong> <br />
            For a limited set of features, we allow users to opt-in to our
            collection of precise location data (GPS data). We only use this
            information to enable these features, such as displaying your
            precise location on a map at a career fair. We do not share precise
            location data with third parties, and we do not combine precise
            location data with personal information for advertising purposes.
          </li>
          <li>
            <strong>Employer data:</strong> <br />
            When Employers create a workwish Employer Account, we request
            contact information, including email address and telephone number,
            to provide a point of contact for partner and administrative staff
            and which is made available on your public profile. We will not use
            your phone number to send any commercial or marketing messages not
            related to your use of workwish Services to you without your express
            consent.
          </li>
        </ul>

        <h3 className='section-title'>How we use your personal data</h3>
        <p className='entry-content'>
          We use, process and store your Personal Data to provide the workwish
          Service and to maximize opportunities to connect you with potential
          employers. This includes:
        </p>
        <ul>
          <li>
            Providing services such as allowing you to make appointments with
            career center professionals or sign up for career fairs and other
            events.
          </li>
          <li>
            Delivering job opportunities and communications from potential
            employers by email in accordance with your account settings and
            communication preferences.
          </li>
          <li>
            Allowing you to contact employers and employers to contact you
            through the workwish Service, including by way of messaging, audio
            and video conferencing, and webinars.
          </li>
          <li>
            Allowing you to post reviews and other content on employers and
            employment experiences.
          </li>
          <li>
            Personalizing the Service. For example, we may suggest employers to
            you based on the information in your resume or the demographic
            information you provide or suggest user with public profiles to
            employers based on search criteria.
          </li>
          <li>
            Contacting you about additional workwish or Partner services you
            might be interested in.
          </li>
          <li>Researching new ways to improve the workwish Service.</li>
        </ul>
        <h3 className='section-title'>When we may share your personal data</h3>
        <p className='entry-content'>
          We will only share your personal data under the following
          circumstances:
        </p>
        <ul>
          <li>
            With your consent. For example, you may choose to make your profile
            public so that we can share it with employers through the Services
            or choose to apply to a job and we will share your profile
            information, resume and transcript with the employers you choose.
            Employers are required to only use workwish data to provide
            employment services and to safeguard any personal data they receive.
          </li>
          <li>
            With vendors that are contractually engaged to provide us with
            services, such as cloud service providers, workwish Communications
            related telecommunications services, and other services for
            maintaining our systems, order fulfillment, email management and
            credit card processing. These companies are obligated by contract to
            safeguard any personal data they receive from us.
          </li>
          <li>
            With vendors that are contractually engaged to provide us with
            services, such as order fulfillment, email management and credit
            card processing. These companies are obligated by contract to
            safeguard any personal data they receive from us.
          </li>
          <li>
            With relevant law enforcement bodies if we believe that disclosure
            is reasonably necessary to comply with a law, regulation, valid
            legal process (e.g., subpoenas or warrants served on us), or
            governmental or regulatory request, to enforce or apply the Terms of
            Service, to protect the security or integrity of the workwish
            Service, and/or to protect the rights, property, or safety of
            workwish, its employees, users, or others. If we are going to
            release your data, we will do our best to provide you with notice in
            advance by email, unless we are prohibited by law from doing so.
          </li>
          <li>
            With professional services, potential purchasers or investors in
            connection with the sale, merger, bankruptcy, sale of assets or
            reorganization of our company. We will notify you if a different
            company will receive your personal data and the promises in this
            Privacy Policy will apply to your data as transferred to the new
            entity.
          </li>
        </ul>
        <h3 className='section-title'>Cookies</h3>
        <p className='entry-content'>
          As a Site visitor, we place a small piece of software referred to as a
          “session cookie” on your computer. If you use the Services and create
          an account, we also use this cookie to recognize you on return visits
          and expedite the login process. You can remove this and other cookies
          through your browser preferences menu, though the exact method varies
          depending on the type of browser you use – but note that you won’t be
          able to log into our service if your cookies are disabled. If you
          visit from a mobile device, we may reference your device identifier or
          other information about where your visit originated.
        </p>
        <p className='entry-content'>
          Our email communications contain web beacons or similar technology
          which track when the email has been opened or read. This functionality
          sends the resulting records back to our Service, which may be
          associated with other information you have provided us.
        </p>
        <h3 className='section-title'>
          Storage, security and how to remove your information
        </h3>
        <p className='entry-content'>
          We use industry standard technical, administrative and physical
          controls to protect your data including encrypting it at rest and as
          it is transferred from you to workwish. While we take reasonable
          precautions against possible security breaches, no website or internet
          transmission is completely secure and we cannot guarantee that
          unauthorized access, hacking, data loss or other breach will never
          occur. In the event of a breach, we will take reasonable steps to
          investigate the situation and, where appropriate, notify affected
          individuals in accordance with any applicable laws and regulations.
        </p>
        <h3 className='section-title'>
          De-activating your account or deleting your personal data
        </h3>
        <p className='entry-content'>
          You can choose to deactivate your account so that you are no longer
          viewable on the Service or you can make your account private. You may
          also request workwish delete information about you, however we may be
          obligated as a service provider to your college or university to
          retain certain data about you. This means that if they re-send us your
          data to process on their behalf, we will retain it unless they request
          we delete it. You can request deactivation or deletion by sending a
          message to{' '}
          <span className='underline hover:text-primary hover:no-underline'>
            contact@workwish.co
          </span>
        </p>
        <h3 className='section-title'>Third party links</h3>
        <p className='entry-content'>
          The Services may contain links to and from third party websites of our
          Employer or other workwish Partners. If you follow a link to any of
          these websites, please note that these websites have their own privacy
          policies and that we do not accept any responsibility or liability for
          their policies. We also cannot be responsible for your personal data
          that you submit to employers using the Services. Please check their
          individual policies before you submit any information to those
          websites.
        </p>
        <p className='entry-content'>
          If you have any questions about our privacy practices, or if you wish
          to remove any of your personal information from our records please
          contact us at :{' '}
          <span className='underline hover:text-primary hover:no-underline'>
            contact@workwish.co
          </span>
        </p>
      </div>
    </div>
  )
}
