import { Link } from 'react-router-dom'

export default function Terms() {
  return (
    <div className='static-wrapper'>
      <div className='container'>
        <h1 className='entry-title'>Term of service</h1>
        <h4 className='mb-8 text-slate-500'>Last updated May 10, 2024</h4>
        <p className='entry-content'>
          We are a career services platform that connects user and employers to
          democratize the job finding experience in college and beyond.
        </p>
        <p className='entry-content'>
          These Terms of Service apply to your use of the services offered by
          ROBORA Company Limited. workwish or our affiliates and service
          providers (collectively "workwish" "we" "us" or "our"), websites,
          including, without limitation, the workwish.co website, tools, data,
          and documentation (collectively, the "Services"). These Terms of
          Service will change over time. If we make minor changes to the Terms
          of Service without materially changing your rights, we will post the
          modified Terms of Service on our website. workwish takes user privacy
          seriously and does not permit third parties to bulk collect user data,
          employer data, job descriptions, or other marketplace information from
          our Services For more information on our privacy practices, please
          read our{' '}
          <Link
            className='underline hover:text-primary hover:no-underline'
            to='/privacy'>
            Privacy Policy
          </Link>
        </p>
        <h3 className='section-title'>User Account Creation</h3>
        <p className='entry-content'>
          You need to have an account to use workwish. You can create an account
          on workwish as a student seeking a job or career advice, as an
          employer looking for exciting new talent, or as a career center
          professional. You agree not to misrepresent any information about
          yourself in creating or using an account.
        </p>
        <p className='entry-content'>
          You have the option to provide additional information when creating
          your user account, such as a profile picture or skills, and to upload
          documents, such as your resume or transcript. Any information or
          content you upload directly to the workwish Service is governed by
          these Terms of Service.
        </p>
        <h3 className='section-title'>User Account Guidelines</h3>
        <p className='entry-content'>
          Your workwish profile is your first impression for employers. As a
          rule of thumb, keep your content and communications on workwish
          professional. Specifically, you are not allowed to post any violent,
          nude, discriminatory, hateful, or sexually suggestive photos or other
          similar content via the Service. You are responsible for all content
          you upload to workwish.
        </p>
        <p className='entry-content'>
          workwish allows you to view profiles and send messages to employers
          through the Service. You agree not to stalk, defame, bully, harass,
          abuse, threaten, intimidate, or impersonate other people or entities.
          You also agree not to use the Service for any illegal or unauthorized
          purpose, including spam, copyright infringement, identity theft, or
          fraud.
        </p>
        <p className='entry-content'>
          You agree you will not attempt to restrict another user from using or
          enjoying the Service and you must not encourage or facilitate
          violations of these Terms of Service or any other workwish terms.
        </p>
        <h3 className='section-title'>Continuing Support</h3>
        <p className='entry-content'>
          workwish is committed to helping user succeed in the professional
          world during school and beyond. We encourage you to update your
          contact information with a personal email address, and to keep your
          profile current. Any information you share directly with workwish is
          subject to these Terms of Service and our{' '}
          <Link
            className='underline hover:text-primary hover:no-underline'
            to='/privacy'>
            Privacy Policy
          </Link>
        </p>
        <h3 className='section-title'>Creating an Employer Account</h3>
        <p className='entry-content'>
          Creating an Employer Account: To create an Employer Account you must
          either associate yourself with a current workwish Employer Partner
          (“Employer Partner”) and be approved by that employer or create a new
          Employer Account. You are prohibited from creating an account for
          anyone other than yourself, unless you have received express
          authorization from an employer or client to do so on their behalf.
          Employer Partners do not have access to any student profiles unless
          they are approved by workwish, to access user information that the
          user chooses to share.
        </p>
        <p className='entry-content'>
          When you create a workwish Employer Account, we request contact
          information, including email address and telephone number, to provide
          a point of contact for user and administrative staff and which will be
          made available on your public profile. We will not use your phone
          number to send any commercial or marketing messages to you from third
          parties. We may share your data with vendors who provide us with
          assistance in preventing or detecting fraud or other misuse of our
          services. You agree to provide accurate and current information and to
          correct any misrepresentations immediately upon discovery. By creating
          an Employer Account you represent and warrant that you own or have
          obtained all rights necessary to allow workwish access to and
          processing of any data, materials or information you provide to
          workwish, and you authorize workwish to access, use and display such
          information for the purpose of providing the Service.
        </p>
        <p className='entry-content'>
          When you create a workwish Employer Account, all user will only have
          access to that information. Once your account has been approved, you
          will see user data through our Service. You are prohibited from
          disclosing or sharing this information with other parties, and agree
          to keep user data confidential. You shall only use user data to
          provide the employer services to the user and not use, sell, or
          disclose workwish Data for any purposes including data analytics, data
          monetization, advertising, marketing or any other purpose even if such
          workwish Data is aggregated or de-identiﬁed. You agree to handle and
          maintain user data with equivalent or superior standards as those
          described in our Privacy Policy. You also agree not to use the Service
          to send spam or other unauthorized communications, and you agree not
          to use any user data for purposes not authorized by workwish. These
          prohibitions are addressed in further detail below.
        </p>
        <h3 className='section-title'>Employer Account Guidelines</h3>
        <p className='entry-content'>
          Through the use of the Service, you will be able to search and filter
          user results based on a wide range of criteria. You agree to maintain
          a fair and equitable recruitment process when selecting user
          candidates. You also agree not to discriminate based on ethnicity,
          national origin, religion, age, gender, sexual orientation,
          disability, or veteran status as prohibited by law. You have the
          ability to contact user directly through the Service. By using this
          Service, you agree not to stalk, defame, bully, harass, abuse,
          threaten, intimidate, or impersonate any people or entities.
        </p>
        <p className='entry-content'>
          Employer Accounts are administered by the employer’s designated
          account administrators. If the employer chooses to allow additional
          individual employees to access the workwish Service through the
          Employer Account, you agree that the account administrators are
          responsible for the use of the Service by those individual employees,
          including requests related to personal data collected from those
          individual employees. You acknowledge and agree that workwish is not
          responsible for the privacy or security practices of your
          organization, which may be different than, but with respect to your
          access to the workwish Service may not be less protective than, these
          Terms of Service or our Privacy Policy.
        </p>
        <p className='entry-content'>
          You agree that your use of the Services is for your own internal use
          as intended by workwish, and your use is non-assignable, with no right
          to resell, reproduce or distribute. workwish reserves the right to
          cancel any Services for failure to follow these Terms of Service.
        </p>
        <p className='entry-content'>
          You acknowledge and agree, moreover, that, to ensure your compliance
          with these Terms of Service, workwish may review the application flow
          associated with any new post before it becomes available to applicants
          on the workwish Service.
        </p>

        <h2 className='large-title'>
          General Guidelines for User and Employers
        </h2>
        <h3 className='section-title'>The Content You Submit Belongs to You</h3>
        <p className='entry-content'>
          workwish does not claim ownership of any content that you post on or
          through the Service. By making your content available on or through
          the Service you grant to workwish a non-exclusive, transferable,
          sublicensable, worldwide, royalty-free license to use, copy, modify,
          publicly display, publicly perform and distribute your content only in
          connection with operating and providing the workwish Service.
        </p>
        <p className='entry-content'>
          You are responsible for your content. You represent and warrant that
          you own your content or that you have all rights necessary to grant us
          a license to use your content as described in these Terms of Service.
          You also represent and warrant that your content and the use and
          provision of your content on the workwish Service will not: (a)
          infringe, misappropriate or violate a third party’s patent, copyright,
          trademark, trade secret, moral rights or other intellectual property
          rights, or rights of publicity or privacy; (b) violate, or encourage
          any conduct that would violate, any applicable law or regulation or
          would give rise to civil liability; (c) be fraudulent, false,
          misleading or deceptive; (d) be defamatory, obscene, pornographic,
          vulgar or offensive; (e) promote discrimination, bigotry, racism,
          hatred, harassment or harm against any individual or group; (f) be
          violent or threatening or promote violence or actions that are
          threatening to any person or entity; or (g) promote illegal or harmful
          activities or substances.
        </p>
        <h3 className='section-title'>
          Our Intellectual Property Belongs to Us
        </h3>
        <p className='entry-content'>
          workwish's content, meaning all content included in the Services,
          except your content (the “workwish Content”), is protected by
          copyright, trademark, patent, trade secret and other laws, and, as
          between you and workwish, we own and retain all rights to the workwish
          Content and the Service. You will not remove, alter or conceal any
          copyright, trademark, service mark or other proprietary rights notices
          incorporated in or accompanying the workwish Content and you will not
          reproduce, modify, adapt, prepare derivative works based on, perform,
          display, publish, distribute, transmit, broadcast, sell, license or
          otherwise exploit the workwish Content.
        </p>
        <p className='entry-content'>
          The workwish name and logo are our trademarks, and may not be copied,
          imitated or used, in whole or in part, without our prior written
          permission. In addition, all page headers, custom graphics, button
          icons and scripts are service marks, trademarks and/or trade dress of
          workwish, and may not be copied, imitated or used, in whole or in
          part, without prior written permission.
        </p>
        <h3 className='section-title'>Third Party Links</h3>
        <p className='entry-content'>
          Our Service contains links to third-party websites, apps, services and
          resources (collectively “Third-Party Services”) that are not under
          workwish’s control. We provide these links only as a convenience and
          are not responsible for the content, products or services that are
          available from Third-Party Services. You acknowledge sole
          responsibility and assume all risk arising from your use of any
          Third-Party Services.
        </p>
        <h3 className='section-title'>
          Violation and Enforcement of These Terms
        </h3>
        <p className='entry-content'>
          We reserve the right to refuse access to the Service to anyone for any
          reason at any time. We reserve the right to force forfeiture of any
          username or account for any reason. We may, but have no obligation to,
          remove, edit, block, and/or monitor content or accounts containing
          content that we determine in our sole discretion violates these Terms
          of Service.
        </p>
        <h3 className='section-title'>Updates</h3>
        <p className='entry-content'>
          From time to time, workwish reserves the right to release updates to
          or upgrades of the Services and to otherwise change or discontinue any
          aspect or feature of the Services. Changes may not be consistent
          across all platforms and devices.
        </p>
        <h2 className='large-title'>Entire Agreement</h2>
        <p className='entry-content'>
          If you are using the Service on behalf of a legal entity, you
          represent that you are authorized to enter into an agreement on behalf
          of that legal entity. These Terms of Service constitute the entire
          agreement between you and workwish and governs your use of the
          Service, unless you have a separate signed agreement with workwish
          that states it supersedes this Terms of Service.
        </p>
        <p className='entry-content'>
          You will not assign the Terms of Service or assign any rights or
          delegate any obligations hereunder, in whole or in part, whether
          voluntarily or by operation of law, without the prior written consent
          of Handshake. Any purported assignment or delegation by you without
          the appropriate prior written consent of Handshake will be null and
          void. Handshake may assign these Terms of Service or any rights
          hereunder without your consent.
        </p>
      </div>
    </div>
  )
}
