export default function About() {
  return (
    <div className='static-wrapper'>
      <div className='container'>
        <h1 className='entry-title'>Build a workplace people love.</h1>
        <div className='grid gap-y-8 md:grid-cols-12 md:gap-x-8'>
          <div className='image md:col-span-6 lg:col-span-5'>
            <img
              src='/img/about2.jpg'
              alt='about'
              className='block h-auto w-full rounded'
            />
          </div>
          <div className='content md:col-span-6 lg:col-span-7'>
            <p className='entry-content'>
              Our goal is to help employees love their workplace (or at least
              not hate it), because more than half of each day, they spend their
              lives in a place we call the "office". Some people spend more than
              12 hours here each day, more than at home, and many spend more
              time with their colleagues than with family or friends.{' '}
              <strong>
                So why shouldn't we make the workplace somewhere they want to
                be, somewhere enjoyable, somewhere exciting to be?
              </strong>
            </p>
            <p className='entry-content'>
              It's not wrong to think that we're here to exchange our time for
              pay, to receive a salary, to spend on ourselves, take care of our
              families, pay monthly bills, and hope to get a bonus to go on
              holiday abroad, using 10 days of vacation. But it's not a
              sustainable path for yourself, and it affects your inner
              well-being. It's like hurting yourself every day by having to
              exchange deep feelings for going to a place you don't want to go,
              doing things you don't like, working with people you hate, and
              following KPI/OKR (or you call it whatever you want) you don't
              even care about.
            </p>
            <p className='entry-content'>
              We've all been part of this, and we know how frustrating it is.
              When we have the opportunity,{' '}
              <strong>
                we want to use everything we have to help support everyone to
                have a workplace worth spending their lives in.
              </strong>{' '}
              When the workplace is somewhere worth going, an environment that
              makes you feel safe, it enhances the best version of yourself.
              When everyone benefits, you're happier, you have inner energy, an
              open-minded attitude, good health, and produce good results. It
              affects the people around you too. Team members notice your
              freshness, leaders see you ready to create new changes. And if
              everyone reinforces each other, the organization will improve too.
            </p>
            <p className='entry-content'>
              <strong>Workwish</strong> wants to be a workplace that maximizes
              the potential of its employees. We care from the beginning to the
              end with these services:
            </p>
            <p className='entry-content'>
              <strong>Candidate Screening:</strong> To ensure you get the right
              people for your company.
            </p>
            <p className='entry-content'>
              <strong>Talent Player Sheet:</strong> To check and make sure
              employees have the potential and are ready to take on new
              challenging positions, suitable for them, so you can keep those
              great people in your company.
            </p>
            <p className='entry-content'>
              <strong>Corporate Check:</strong> To capture the overall picture
              of the company's culture and the presence we have, compared to the
              high-level executives' dream, to correctly plan and use the data
              to benefit everyone and bring the company closer to success.
            </p>
            <p className='entry-content'>
              The important question we should ask ourselves every day is:{' '}
              <strong>Do we have the "right people"</strong> here today? And if
              so,{' '}
              <strong>
                how much? Do we have enough to drive the company to achieve our
                goal?
              </strong>
            </p>
            <p className='entry-content'>
              As company management, no one can answer this question except for
              yourself. If you think what we're doing might benefit you, please
              contact us at{' '}
              <span className='underline'>contact@workwish.co</span>
            </p>
            <p className='entry-content font-bold'>
              You want to create a place to work that's enjoyable for your
              people. If yes, we share the same goal!
            </p>
            <p className='entry-content mt-8 !text-sm'>
              <strong>Bodin(Mike) Luangtrirat</strong>
              <br />
              Co-Founder
              <br />
              <br />
              <strong>Pramote(Pop) Keeratikriengkrai</strong>
              <br />
              Co-Founder
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
