import { Link } from "react-router-dom";

export default function ErrorPage() {
  return (
    <div className="min-h-96 py-20 text-center">
      <div className="container mx-auto">
        <h1 className="text-6xl font-bold mb-8">404</h1>
        <p>Page not found</p>
        <Link to="/" className="underline">Homepage</Link>
      </div>
    </div>
  )
}