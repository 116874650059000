import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Sheet, SheetContent, SheetHeader, SheetTitle } from './ui/sheet'
import { Menu } from 'lucide-react'

export default function Header() {
  const location = useLocation()
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <>
      <header
        className={`header-wrapper ${location.pathname !== '/' ? 'bg-primary' : ''}`}>
        <div className='container'>
          <div className='relative z-10 flex w-full flex-row items-center justify-between'>
            {/* logo */}
            <div className='logo-wrapper flex-1 lg:basis-[240px]'>
              <Link to='/'>
                <img
                  src='/img/site-logo.png'
                  className='h-10 max-w-full'
                  alt='Logo'
                />
              </Link>
            </div>
            {/* menu */}
            <nav className='menu-wrapper basis-[64px] text-right md:basis-auto lg:flex-1'>
              {/* toggle */}
              <button
                className='mobile-toggle relative top-1 bg-transparent px-2 md:!hidden'
                type='button'
                onClick={() => setMobileMenuOpen(true)}>
                <Menu className='h-8 w-8 text-white' />
              </button>
              {/* menu */}
              <ul className='hidden w-full flex-row justify-end gap-x-8 md:!flex'>
                <li>
                  <Link to='/about' className='text-xl text-white'>
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to='/terms' className='text-xl text-white'>
                    Terms
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
      <Sheet open={isMobileMenuOpen}>
        <SheetContent
          side={'right'}
          className='w-[300px] lg:!hidden'
          onClose={() => setMobileMenuOpen(false)}>
          <SheetHeader className='mb-4'>
            <SheetTitle className='text-left'>WorkWish.co</SheetTitle>
          </SheetHeader>
          <nav>
            <ul className=''>
              <li className='' onClick={() => setMobileMenuOpen(false)}>
                <Link
                  to='/'
                  className='block border-b border-slate-200 py-4 text-lg hover:text-primary'>
                  Home
                </Link>
              </li>
              <li className='' onClick={() => setMobileMenuOpen(false)}>
                <Link
                  to='/about'
                  className='block border-b border-slate-200 py-4 text-lg hover:text-primary'>
                  About Us
                </Link>
              </li>
              <li className='' onClick={() => setMobileMenuOpen(false)}>
                <Link
                  to='/terms'
                  className='block border-b border-slate-200 py-4 text-lg hover:text-primary'>
                  Term of Service
                </Link>
              </li>
              <li className='' onClick={() => setMobileMenuOpen(false)}>
                <Link
                  to='/privacy'
                  className='block border-b border-slate-200 py-4 text-lg hover:text-primary'>
                  Privacy Policy
                </Link>
              </li>
              <li className='' onClick={() => setMobileMenuOpen(false)}>
                <Link
                  to='/cookie-policy'
                  className='block border-b-0 border-slate-200 py-4 text-lg hover:text-primary'>
                  Cookies Policy
                </Link>
              </li>
            </ul>
          </nav>
        </SheetContent>
      </Sheet>
    </>
  )
}
