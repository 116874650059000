import './App.css'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import About from './routes/About'
import ErrorPage from './routes/ErrorPage'
import AppLayout from './routes/root'
import Homepage from './routes/Homepage'
import Terms from './routes/Terms'
import Privacy from './routes/Privacy'
import CookiePolicy from './routes/Cookie'

const router = createBrowserRouter([
  {
    path: '/',
    element: <AppLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <Homepage />,
      },
      {
        path: '/about',
        element: <About />,
      },
      {
        path: '/terms',
        element: <Terms />,
      },
      {
        path: '/privacy',
        element: <Privacy />,
      },
      {
        path: '/cookie-policy',
        element: <CookiePolicy />,
      },
    ],
  },
])

function App() {
  return <RouterProvider router={router} />
}

export default App
